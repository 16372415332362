import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    questions: {
        filler: '',
        userID: '',
        subjectID: '',
        age: '',
        presence: '',
        note: '',
        Q1: '', 
        Q2: '', 
        Q3: '', 
        Q3A: '',
        Q3B: '',
        Q3C: '',
        Q4: '',
        Q4A: '',
        Q4B: '',
        Q4C: '',
        Q5: '',
        Q5A: '',
        Q5B: '',
        score: 'Not enough information',
        disagree: '',
    },
    loading: 'idle',
}

const GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbz62fkiGjW8GCgvbDkPrz4h_826eOZKMFACoPNI85OHnRM7a0qVcNWu7jZdD3Cf-__d-w/exec'

export const submitResearchQuestions = createAsyncThunk(
    'researchQuestions/submitResearchQuestions',
    async ({payload, another}) => {
        let modifiedPayload = {...payload};
        for (let key in modifiedPayload) {
            if (modifiedPayload[key] === 'true') {
                modifiedPayload[key] = '1';
            } else if (modifiedPayload[key] === 'false') {
                modifiedPayload[key] = '0';
            }
        }
        const data = JSON.stringify(Object.keys(modifiedPayload).map(key => modifiedPayload[key]));
        console.log('Attempting to Post Data: ' + data );
        const response = await axios({
            method: 'post',
            url: GOOGLE_SCRIPT_URL,
            data,
        })
        .then(response => {
            console.log('Success: ' + response.data);
            return another
        })
        .catch(error => {
            return error
        })
        return response
    }
)


const researchQuestionsSlice = createSlice({
    name: 'researchQuestions',
    initialState,
    reducers: {
        setQuestion: (state, action) => {
            state.questions[action.payload.index] = action.payload.value;
        },
        resetQuestions: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitResearchQuestions.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(submitResearchQuestions.fulfilled, (state, action) => {
            state.loading = 'idle';
        })  
        .addCase(submitResearchQuestions.rejected, (state) => {
            state.loading = 'error';
        });
    }
});

export const { setQuestion, resetQuestions } = researchQuestionsSlice.actions;
export const selectResearchQuestions = state => state.researchQuestions;
export default researchQuestionsSlice.reducer;